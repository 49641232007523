<template>
  <v-alert
    v-if="exibeResumo"
    id="import-bdgd-summary"
    v-bind="vAlertOptions"
    class="txt-v-alert-bold my-0 pb-0"
    :type="vCardStyleType"
    :icon="false"
  >
    <p
      v-if="preImportadas"
      style="color: green"
    >
      Linhas pré-importadas: {{ preImportadas | parseNumberToIntegerBR }}
    </p>
    <p
      v-if="errosPreImportacao"
      style="color: #fb8c00"
    >
      Alertas na etapa de validação:
      {{ errosPreImportacao | parseNumberToIntegerBR }}
    </p>
    <p
      v-if="numEntidadesComAlertas"
      style="color: #fb8c00"
    >
      Entidades com alertas na etapa de validação ({{
        numEntidadesComAlertas
      }}): {{ entidadesComAlertasTxt }}
    </p>
    <p
      v-if="naoImportadas"
      style="color: red"
    >
      Linhas não importadas: {{ naoImportadas | parseNumberToIntegerBR }}
    </p>
    <p
      v-if="numEntidadesComFalhas"
      style="color: red"
    >
      Entidades com falhas ({{ numEntidadesComFalhas }}):
      {{ entidadesComFalhasTxt }}
    </p>
    <p
      v-if="importados"
      style="color: green"
    >
      Linhas importadas: {{ importados | parseNumberToIntegerBR }}
      <span v-if="percentualLinhasImportadas > 0">
        ({{ (100 * percentualLinhasImportadas) | parseNumberToFloatBR }}%)
      </span>
    </p>
  </v-alert>
</template>

<script>
export default {
  props: {
    contadores: {
      type: Object,
      required: true
    },
    arquivos: {
      type: Object,
      required: true
    }
  },

  mounted() {
    if (this.exibeResumo) this.$emit('stepTourSummaryReleased');
  },

  computed: {
    exibeResumo() {
      return this.preImportadas || this.errosPreImportacao || this.importados
        ? true
        : false;
    },

    preImportadas() {
      return Object.values(this.contadores).reduce(
        (acumulador, contador) => (acumulador += contador.preImportados),
        0
      );
    },

    errosPreImportacao() {
      return Object.values(this.contadores).reduce(
        (acumulador, contador) => (acumulador += contador.errosPreImportacao),
        0
      );
    },

    importados() {
      return Object.values(this.contadores).reduce(
        (acumulador, contador) => (acumulador += contador.importados),
        0
      );
    },

    percentualLinhasImportadas() {
      let perc = 0;
      if (this.preImportadas) {
        perc = this.importados / this.preImportadas;
      }
      return perc;
    },

    entidadesComAlertas() {
      const entidadesComAlertas = new Set();
      Object.entries(this.contadores).forEach(function ([
        entidade,
        contadores
      ]) {
        if (contadores.errosPreImportacao) {
          entidadesComAlertas.add(entidade.toUpperCase());
        }
      });
      return Array.from(entidadesComAlertas);
    },

    entidadesComFalhas() {
      const entidadesComFalhas = new Set();
      Object.entries(this.arquivos).forEach(function ([entidade, arquivos]) {
        arquivos.forEach(function (arquivo) {
          const { status_pre, status_importacao } = arquivo;
          if (status_pre === 'FALHA' || status_importacao === 'FALHA') {
            entidadesComFalhas.add(entidade);
          }
        });
      });
      return Array.from(entidadesComFalhas);
    },

    numEntidadesComAlertas() {
      return this.entidadesComAlertas.length;
    },

    entidadesComAlertasTxt() {
      let entidadesComAlertas = [...this.entidadesComAlertas];
      return entidadesComAlertas.sort().join(', ');
    },

    naoImportadas() {
      let contadores = this.contadores;
      let naoImportados = 0;
      Object.entries(this.arquivos).forEach(function ([entidade, arquivos]) {
        let { preImportados, importados } = contadores[entidade.toLowerCase()];
        let naoImportadosEntidade = preImportados - importados;
        arquivos.forEach(function (arquivo) {
          const { status_importacao } = arquivo;
          if (
            status_importacao === 'AGUARDANDO' ||
            status_importacao === 'EXECUTANDO'
          ) {
            naoImportadosEntidade = 0;
          }
        });
        naoImportados += naoImportadosEntidade;
      });
      return naoImportados < 0 ? 0 : naoImportados;
    },

    numEntidadesComFalhas() {
      return this.entidadesComFalhas.length;
    },

    entidadesComFalhasTxt() {
      let entidadesComFalhas = [...this.entidadesComFalhas];
      return entidadesComFalhas.sort().join(', ');
    },

    vAlertOptions() {
      return {
        border: 'left',
        elevation: '2',
        'colored-border': true
      };
    },

    vCardStyleType() {
      let typeError = this.naoImportadas || this.numEntidadesComFalhas;
      let typeWarning = this.errosPreImportacao || this.numEntidadesComAlertas;

      if (typeError) {
        return 'error';
      } else if (typeWarning) {
        return 'warning';
      } else {
        return 'success';
      }
    }
  }
};
</script>

<style>
.txt-v-alert-bold {
  padding: 10px 30px !important;
}

.txt-v-alert-bold div div {
  font-weight: bold !important;
  font-size: 13px !important;
}

.txt-v-alert-bold p:last-child {
  margin-bottom: 10px;
}
</style>
